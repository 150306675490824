import React, { Component } from 'react';
import './FAQ.css'

export class FAQ extends Component {
    static displayName = FAQ.name;

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps) {

    }

    render() {
        return (
            <div className="flex-fill">

                <h1>Frequently Asked Questions</h1>
                <p>[COMING SOON]</p>

            </div>
        );
    }
}
