export class User {

    constructor(user) {
        this.user = user;
        //console.log("SET USER", this.user);
    }

    Get() {
        return this.user;
    }

    Study() {
        return this.user.study;
    }

    IsDeveloper() {
        if (this.user)
            return this.user.role === "Developer"
        else
            return false;
    }

    IsAdmin() {
        if (this.user)
            return this.user.role === "Admin"
        else
            return false;
    }

    IsUser() {
        if (this.user)
            return this.user.role === "User"
        else
            return false;
    }

    IsGuest() {
        if (this.user)
            return this.user.role === "Guest"
        else
            return true;
    }

    IsLoggedIn() {
        if (this.user)
            return this.user.isLoggedIn;
        else
            return false;
    }

    Role() {
        if (this.user)
            return this.user.role;
        else
            return false;
    }

    AppVersion() {
        if (this.user)
            return this.user.appVersion;
        else
            return null;
    }

    Name() {
        if (this.user)
            return this.user.name;
        else
            return null;
    }

    Email() {
        if (this.user)
            return this.user.email;
        else
            return null;
    }

    RegistrationCode() {
        if (this.user && this.user.study && this.user.study.registrationCode) {
            return `${this.user.study.registrationCode.substring(0, 2)}-${this.user.study.registrationCode.substring(2, 5)}-${this.user.study.registrationCode.substring(5, 9)}`;
        } else
            return this.Email();
    }

    ConsentCookie() {
        if (this.user)
            return this.user.consentCookie;
        else
            return null;
    }

    SetConsentCookie() {
        console.log("Accepted Cookies");
        document.cookie = this.ConsentCookie().cookieString;
        this.user.consentCookie = {
            show: false,
            cookieString: null,
        }
    }

    UserStudy() {
        if (this.user && this.user.study) 
            return this.user.study;
        else
            return null;
    }
}

export class Device {

    constructor(isTouchDevice, isPhone, isTablet, orientation, isInstalledPWA, localOptions) {
        this.device = { isTouchDevice: isTouchDevice, isPhone: isPhone, isTablet: isTablet, orientation: orientation, isInstalledPWA: isInstalledPWA, localOptions: localOptions };
        //console.log("SET DEVICE", this.device);
    }

    IsTouchDevice() {
        return this.device && this.device.isTouchDevice;
    }

    IsPhone() {
        return this.device && this.device.isPhone;
    }

    IsTablet() {
        return this.device && this.device.isTablet;
    }

    ShowMobileUI() {
        return this.IsTouchDevice() && (this.IsPhone() || this.IsTablet())
    }

    Orientation() {
        if (this.device && this.device.orientation)
            return this.device.orientation;
        else
            return "Portrait";
    }

    IsInstalledPWA() {
        if (this.device && this.device.isInstalledPWA)
            return this.device.isInstalledPWA;
        else
            return false;
    }

    Get() {
        return this.device;
    }
}

export class Format {

    constructor() {
        this.validate = new Validate();
    }

    FormatDate(value) {
        if (value) {
            var options = { year: 'numeric', month: 'numeric', day: 'numeric' }; //, timeZone: "UTC", timeZoneName: "short"
            var date = value.toUpperCase().endsWith('Z') ? new Date(value) : new Date(value + 'Z');
            return date.toLocaleDateString(undefined, options);
        } else {
            return "";
        }
    }

    FormatDateTime(value) {
        if (value) {
            var options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit", second: "2-digit" }; //, timeZone: "UTC", timeZoneName: "short"
            var date = value.toUpperCase().endsWith('Z') ? new Date(value) : new Date(value + 'Z');
            return date.toLocaleDateString(undefined, options);
        } else {
            return "";
        }
    }

    FormatJsDateTime(date) {
        if (date) {
            var options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit", second: "2-digit" }; //, timeZone: "UTC", timeZoneName: "short"
            return date.toLocaleDateString(undefined, options);
        } else {
            return "";
        }
    }

    DateTime(value) {
        if (!this.validate.IsEmpty(value)) {
            var options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit", second: "2-digit" }; //, timeZone: "UTC", timeZoneName: "short"
            var date = value.toUpperCase().endsWith('Z') ? new Date(value) : new Date(value + 'Z');
            return date.toLocaleDateString(undefined, options);
        } else {
            return "";
        }
    }

    TimeSpanInSeconds(value) {
        var result = 0;
        if (!this.validate.IsEmpty(value)) {
            var parts = value.split(":");
            var days = "0";
            var hours = parts[0];
            var minutes = parts.length > 1 ? parts[1] : "0";
            var seconds = parts.length > 2 ? parts[2] : "0";
            if (hours.indexOf(".") > -1) {
                var hourParts = hours.split(".");
                days = hourParts[0];
                hours = hourParts[1];
            }
            if (seconds.indexOf(".") > -1) {
                var secondParts = hours.split(".");
                seconds = secondParts[0];
            }
            result = (parseInt(days) * 86400) + (parseInt(hours) * 3600) + (parseInt(minutes) * 60) + parseInt(seconds);
        }
        return result;
    }

    TimeSpan(value) {
        var result = "";
        if (!this.validate.IsEmpty(value)) {
            var parts = value.split(":");
            var days = "0";
            var hours = parts[0];
            var minutes = parts.length > 1 ? parts[1] : "0";
            var seconds = parts.length > 2 ? parts[2] : "0";
            if (hours.indexOf(".") > -1) {
                var hourParts = hours.split(".");
                days = hourParts[0];
                hours = hourParts[1];
            }
            if (seconds.indexOf(".") > -1) {
                var secondParts = hours.split(".");
                seconds = secondParts[0];
            }
            if (parseInt(days) > 10000000)
                result = "Unlimited";
            else {
                if (parseInt(days) === 0 && parseInt(hours) === 0 && parseInt(minutes) === 0)
                    result = "Immediate"
                else {
                    if (parseInt(days) === 1)
                        result += parseInt(days).toString() + " day ";
                    else if (parseInt(days) > 0)
                        result += parseInt(days).toString() + " days ";
                    if (parseInt(hours) === 1)
                        result += parseInt(hours).toString() + " hour ";
                    else if (parseInt(hours) > 0)
                        result += parseInt(hours).toString() + " hours ";
                    if (parseInt(minutes) === 1)
                        result += parseInt(minutes).toString() + " minute ";
                    else if (parseInt(minutes) > 0)
                        result += parseInt(minutes).toString() + " minutes ";
                }
            }
        } else {
            result = "zero minutes"
        }
        return result.trim();
    }

    TimeBetween(startDateStr, endDateStr) {
        let delta = ((new Date(endDateStr)) - (new Date(startDateStr))) / 1000;
        if (delta > 0) {

            // calculate (and subtract) whole days
            var days = Math.floor(delta / 86400);
            delta -= days * 86400;

            // calculate (and subtract) whole hours
            var hours = Math.floor(delta / 3600) % 24;
            delta -= hours * 3600;

            // calculate (and subtract) whole minutes
            var minutes = Math.floor(delta / 60) % 60;
            delta -= minutes * 60;

            // what's left is seconds
            var seconds = Math.floor(delta % 60);

            if (days > 0) {
                if (hours > 12) {
                    return `${days + 1} day${(days + 1) !== 1 ? 's' : ''}`;
                } else {
                    return `${days} day${days !== 1 ? 's' : ''}`;
                }
            }
            else if (hours > 0 || minutes > 0 || seconds > 0)
                return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
            else
                return 'Now';
        } else {
            return 'Expired';
        }
    }

    ExactTimeBetween(startDateStr, endDateStr) {
        let delta = (endDateStr - startDateStr) / 1000;

        // calculate (and subtract) whole days
        var days = Math.floor(delta / 86400);
        delta -= days * 86400;

        // calculate (and subtract) whole hours
        var hours = Math.floor(delta / 3600) % 24;
        delta -= hours * 3600;

        // calculate (and subtract) whole minutes
        var minutes = Math.floor(delta / 60) % 60;
        delta -= minutes * 60;

        // what's left is seconds
        var seconds = Math.floor(delta % 60);

        return `${days} day${days !== 1 ? 's' : ''} ${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    }
}

export class Validate {

    IsEmpty(value) {
        return (!value || /^\s*$/.test(value));
    }

    ArraysAreEqual(array1, array2) {
        return (array1.length === array2.length) && array1.every((element, index) => {
            return element === array2[index];
        });
    }

}

export class Theme {

    constructor(device) {
        this.device = device;
        this.defaultBackgroundColour = window.getComputedStyle(document.body, null).getPropertyValue('--main-bg-color');
        this.rgba2hex = (rgba) => `#${rgba.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+\.{0,1}\d*))?\)$/).slice(1).map((n, i) => (i === 3 ? Math.round(parseFloat(n) * 255) : parseFloat(n)).toString(16).padStart(2, '0').replace('NaN', '')).join('')}`
        this.SetBackgroundColour(this.defaultBackgroundColour);
    }

    SetBackgroundColour(backgroundColour) {
        if (backgroundColour) {
            if (backgroundColour.indexOf('rgb') > -1)
                this.backgroundColour = this.rgba2hex(backgroundColour);
            else
                this.backgroundColour = backgroundColour;
        } else {
            this.backgroundColour = this.defaultBackgroundColour;
        }
    }

    GetBackgroundColour() {
        return this.backgroundColour ?? this.defaultBackgroundColour;
    }

    GetTextBootstrapContrastVariant() {
        return this.IsLightBackground() ? 'text-dark' : 'text-light';
    }

    GetSpinnerContrastVariant() {
        return this.IsLightBackground() ? 'dark' : 'light';
    }

    GetPlayBackgroundVariant() {
        return this.IsLightBackground() ? 'light' : 'dark';
    }

    GetTabsContrastVariant() {
        return this.IsLightBackground() ? 'tabs-light' : 'tabs-dark';
    }

    GetPaginationClassVariant() {
        return this.IsLightBackground() ? 'pagination-light' : 'pagination-dark';
    }

    GetPlayGameClass() {
        if (this.device && this.device.IsTablet())
            return this.IsLightBackground() ? 'clickable-div-dark clickable-div-halfsize text-dark' : 'clickable-div-light clickable-div-halfsize text-light';
        else
            return this.IsLightBackground() ? 'clickable-div-dark clickable-div-fullsize text-dark' : 'clickable-div-light clickable-div-fullsize text-light';
    }

    GetPlayGameProgressClass() {
        return this.IsLightBackground() ? 'game-progress-bar-dark' : 'game-progress-bar-light';
    }

    GetModalConfirmHeaderClassName() {
        return this.IsLightBackground() || (this.device && !this.device.ShowMobileUI()) ? 'modal-confirm-header-app-light' : 'modal-confirm-header-app-dark';
    }

    GetModalConfirmBodyClassName() {
        return this.IsLightBackground() || (this.device && !this.device.ShowMobileUI()) ? 'bg-light text-dark' : 'bg-dark text-light';
    }

    IsLightBackground() {
        if (this.backgroundColour && this.backgroundColour.indexOf("#", 0) >= 0) {
            let hexcolor = this.backgroundColour.replace('#', '');
            let r = parseInt(hexcolor.substring(0, 2), 16);
            let g = parseInt(hexcolor.substring(2, 4), 16);
            let b = parseInt(hexcolor.substring(4, 6), 16);
            let yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
            //console.log(`IsLightBackground ${hexcolor} = R${r}:G${g}:B${b}`, yiq, (yiq >= 128))
            return (yiq >= 128);
        } else {
            return true;
        }
    }

    Get() {
        return { backgroundColour: this.GetBackgroundColour(), isLightBackground: this.IsLightBackground() }
    }
}
