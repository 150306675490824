import update from 'immutability-helper';
import { ConsoleLogging, ErrorHandler } from './APIHelpers'

export class StudyApi {

    constructor(endPoint) {
        this.endPoint = endPoint ?? "study";
        this.errorHandler = new ErrorHandler();
        this.consoleLog = new ConsoleLogging();
    }

    async UploadFile(file, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/upload`;
        var formData = new FormData()
        formData.append('file', file);
        let postData = {
            method: "POST",
            body: formData,
        };
        await this.Fetch(url, postData, callBackSuccessFn, callBackErrorFn);

    }

    // StudyApi
    async Query(dataQuery, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/query`;
        let formData = new FormData();
        formData.append('dataQuery', JSON.stringify(dataQuery));
        let postData = {
            method: "POST",
            body: formData,
        };
        await this.Fetch(url, postData, callBackSuccessFn, callBackErrorFn);

    }

    // StudyApi
    async List(callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/list`;
        await this.Fetch(url, null, callBackSuccessFn, callBackErrorFn);

    }

    // StudyApi
    async List(callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/list`;
        await this.Fetch(url, null, callBackSuccessFn, callBackErrorFn);

    }

    // StudyApi
    async Get(studyId, callBackSuccessFn, callBackErrorFn) {

        if (studyId > 0) {
            let url = `${this.endPoint}/Get/${studyId}`;
            await this.Fetch(url, null, callBackSuccessFn, callBackErrorFn);
        } else {
            this.consoleLog.LogError(`${this.endPoint}/Get/${studyId}`, "INVALID PARAMETERS");
        }

    }

    // StudyApi
    //async GetFacts(studyId, callBackSuccessFn, callBackErrorFn) {

    //    if (studyId > 0) {
    //        let url = `${this.endPoint}/GetFacts/${studyId}`;
    //        await this.Fetch(url, null, callBackSuccessFn, callBackErrorFn);
    //    } else {
    //        this.consoleLog.LogError(`${this.endPoint}/GetFacts/${studyId}`, "INVALID PARAMETERS");
    //    }

    //}

    // StudyApi
    async Schedule(callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/Schedule`;
        await this.Fetch(url, null, callBackSuccessFn, callBackErrorFn);

    }

    // StudyApi
    async FindParticipant(searchString, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/FindParticipant/${searchString}`;
        await this.Fetch(url, null, callBackSuccessFn, callBackErrorFn);

    }

    // BatteryApi
    async GetRandomisationResults(participantStudyId, code, version, instance, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/GetRandomisationResults/${participantStudyId}/${code}/${version}/${instance}`;
        if (participantStudyId && code && version) {

            await this.Fetch(url, null, callBackSuccessFn, callBackErrorFn);

        } else {
            this.consoleLog.LogError(url, "INVALID PARAMETERS");
        }

    }

    // StudyApi
    async TriggerActivity(participantStudyId, code, version, instance, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/TriggerActivity`;
        let formData = new FormData();
        formData.append('participantStudyId', participantStudyId);
        formData.append('code', code);
        formData.append('version', version);
        formData.append('instance', instance);
        let postData = {
            method: "POST",
            body: formData,
        };
        await this.Fetch(url, postData, callBackSuccessFn, callBackErrorFn);

    }

    // StudyApi
    async ResetActivity(participantStudyId, code, version, instance, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/ResetActivity`;
        let formData = new FormData();
        formData.append('participantStudyId', participantStudyId);
        formData.append('code', code);
        formData.append('version', version);
        formData.append('instance', instance);
        let postData = {
            method: "POST",
            body: formData,
        };
        await this.Fetch(url, postData, callBackSuccessFn, callBackErrorFn);

    }

    // StudyApi
    async PassActivity(participantStudyId, code, version, instance, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/PassActivity`;
        let formData = new FormData();
        formData.append('participantStudyId', participantStudyId);
        formData.append('code', code);
        formData.append('version', version);
        formData.append('instance', instance);
        let postData = {
            method: "POST",
            body: formData,
        };
        await this.Fetch(url, postData, callBackSuccessFn, callBackErrorFn);

    }

    // StudyApi
    async FailActivity(participantStudyId, code, version, instance, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/FailActivity`;
        let formData = new FormData();
        formData.append('participantStudyId', participantStudyId);
        formData.append('code', code);
        formData.append('version', version);
        formData.append('instance', instance);
        let postData = {
            method: "POST",
            body: formData,
        };
        await this.Fetch(url, postData, callBackSuccessFn, callBackErrorFn);

    }

    // StudyApi
    async Randomise(participantStudyId, code, version, instance, callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/Randomise`;
        let formData = new FormData();
        formData.append('participantStudyId', participantStudyId);
        formData.append('code', code);
        formData.append('version', version);
        formData.append('instance', instance);
        let postData = {
            method: "POST",
            body: formData,
        };
        await this.Fetch(url, postData, callBackSuccessFn, callBackErrorFn);

    }

    async Fetch(url, data, callBackSuccessFn, callBackErrorFn) {

        try {
            if (data)
                this.consoleLog.Log(`POST: ${url}`, data)
            else
                this.consoleLog.Log(`GET: ${url}`)

            await fetch(url, data)
                .then(async response => {
                    if (!response.ok || (response.url && response.url.indexOf("Identity/Account") > -1))
                        await this.errorHandler.ReturnProblemDetails(response, callBackErrorFn);
                    else {
                        let data = await response.json();
                        if (data) {
                            if (data.hasOwnProperty('errors')) {

                                this.consoleLog.LogError(data.errors);
                                if (callBackErrorFn)
                                    callBackErrorFn({ error: data.errors });

                            } else {

                                this.consoleLog.Log(url, data);
                                if (callBackSuccessFn)
                                    callBackSuccessFn(data);
                            }
                        } else {
                            this.errorHandler.ReturnErrorMessage("We couldn't find what you were looking for.", callBackErrorFn);
                        }
                    }
                })
                .catch(error => {
                    this.errorHandler.ReturnErrorMessage(error, callBackErrorFn);
                });
        } catch (error) {
            this.errorHandler.ReturnErrorMessage(error, callBackErrorFn);
        }
    }
}

export class AdminApi {

    constructor(endPoint) {
        this.endPoint = endPoint ?? "adminApi";
        this.errorHandler = new ErrorHandler();
        this.consoleLog = new ConsoleLogging();
        this.ApiKey = '1526e04a-65b1-484c-8a3d-25c936d1daad';
    }

    // AdminApi
    async UpdateParticipantSchedules(callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/UpdateParticipantSchedules`;
        let formData = new FormData();
        formData.append('key', this.ApiKey);
        let postData = {
            method: "POST",
            body: formData,
        };
        await this.FetchNoResults(url, postData, callBackSuccessFn, callBackErrorFn);

    }

    // AdminApi
    async UpdateBrainTrainingSummaries(callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/UpdateBrainTrainingSummaries`;
        let formData = new FormData();
        formData.append('key', this.ApiKey);
        let postData = {
            method: "POST",
            body: formData,
        };
        await this.FetchNoResults(url, postData, callBackSuccessFn, callBackErrorFn);

    }

    // AdminApi
    async UpdateStudySummary(callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/UpdateStudySummary`;
        let formData = new FormData();
        formData.append('key', this.ApiKey);
        let postData = {
            method: "POST",
            body: formData,
        };
        await this.FetchNoResults(url, postData, callBackSuccessFn, callBackErrorFn);

    }

    // AdminApi
    async QueueStudyNotifications(callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/QueueStudyNotifications`;
        let formData = new FormData();
        formData.append('key', this.ApiKey);
        let postData = {
            method: "POST",
            body: formData,
        };
        await this.FetchNoResults(url, postData, callBackSuccessFn, callBackErrorFn);

    }

    // AdminApi
    async QueueBrainTrainingNotifications(callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/QueueBrainTrainingNotifications`;
        let formData = new FormData();
        formData.append('key', this.ApiKey);
        let postData = {
            method: "POST",
            body: formData,
        };
        await this.FetchNoResults(url, postData, callBackSuccessFn, callBackErrorFn);

    }

    // AdminApi
    async FlagExpiredActivitiesForExtraction(callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/FlagExpiredActivitiesForExtraction`;
        let formData = new FormData();
        formData.append('key', this.ApiKey);
        let postData = {
            method: "POST",
            body: formData,
        };
        await this.FetchNoResults(url, postData, callBackSuccessFn, callBackErrorFn);

    }

    // AdminApi
    async FlagWithdrawnParticipantsForDeletion(callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/FlagWithdrawnParticipantsForDeletion`;
        let formData = new FormData();
        formData.append('key', this.ApiKey);
        let postData = {
            method: "POST",
            body: formData,
        };
        await this.FetchNoResults(url, postData, callBackSuccessFn, callBackErrorFn);

    }

    // AdminApi
    async SendEmails(callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/SendEmails`;
        let formData = new FormData();
        formData.append('key', this.ApiKey);
        let postData = {
            method: "POST",
            body: formData,
        };
        await this.FetchNoResults(url, postData, callBackSuccessFn, callBackErrorFn);

    }

    // AdminApi
    async BuildStatusReport(callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/BuildStatusReport`;
        let formData = new FormData();
        formData.append('key', this.ApiKey);
        let postData = {
            method: "POST",
            body: formData,
        };
        await this.FetchNoResults(url, postData, callBackSuccessFn, callBackErrorFn);

    }

    // AdminApi
    async BuildSourceReport(callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/BuildSourceReport`;
        let formData = new FormData();
        formData.append('key', this.ApiKey);
        let postData = {
            method: "POST",
            body: formData,
        };
        await this.FetchNoResults(url, postData, callBackSuccessFn, callBackErrorFn);

    }

    async ExtractResults(callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/ExtractResults`;
        let formData = new FormData();
        formData.append('key', this.ApiKey);
        let postData = {
            method: "POST",
            body: formData,
        };
        await this.FetchNoResults(url, postData, callBackSuccessFn, callBackErrorFn);

    }

    async FetchNoResults(url, data, callBackSuccessFn, callBackErrorFn) {

        try {
            if (data)
                this.consoleLog.Log(`POST: ${url}`, data)
            else
                this.consoleLog.Log(`GET: ${url}`)

            await fetch(url, data)
                .then(async response => {
                    if (!response.ok || (response.url && response.url.indexOf("Identity/Account") > -1))
                        await this.errorHandler.ReturnProblemDetails(response, callBackErrorFn);
                    else {
                        this.consoleLog.Log(url, data);
                        if (callBackSuccessFn)
                            callBackSuccessFn(null);
                    }
                })
                .catch(error => {
                    this.errorHandler.ReturnErrorMessage(error, callBackErrorFn);
                });
        } catch (error) {
            this.errorHandler.ReturnErrorMessage(error, callBackErrorFn);
        }
    }

    async Fetch(url, data, callBackSuccessFn, callBackErrorFn) {

        try {
            if (data)
                this.consoleLog.Log(`POST: ${url}`, data)
            else
                this.consoleLog.Log(`GET: ${url}`)

            await fetch(url, data)
                .then(async response => {
                    if (!response.ok || (response.url && response.url.indexOf("Identity/Account") > -1))
                        await this.errorHandler.ReturnProblemDetails(response, callBackErrorFn);
                    else {
                        let data = await response.json();
                        if (data) {
                            if (data.hasOwnProperty('errors')) {

                                this.consoleLog.LogError(data.errors);
                                if (callBackErrorFn)
                                    callBackErrorFn({ error: data.errors });

                            } else {

                                this.consoleLog.Log(url, data);
                                if (callBackSuccessFn)
                                    callBackSuccessFn(data);
                            }
                        } else {
                            this.errorHandler.ReturnErrorMessage("We couldn't find what you were looking for.", callBackErrorFn);
                        }
                    }
                })
                .catch(error => {
                    this.errorHandler.ReturnErrorMessage(error, callBackErrorFn);
                });
        } catch (error) {
            this.errorHandler.ReturnErrorMessage(error, callBackErrorFn);
        }
    }
}

export class AccountApi {

    constructor(endPoint) {
        this.endPoint = endPoint ?? "account";
        this.errorHandler = new ErrorHandler();
        this.consoleLog = new ConsoleLogging();
    }

    // AccountApi
    async MyDetails(callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/mydetails`;
        await this.Fetch(url, null, callBackSuccessFn, callBackErrorFn);
    }

    // AccountApi
    async KeepLoggedIn(callBackSuccessFn, callBackErrorFn) {

        try {
            let url = `${this.endPoint}/KeepLoggedIn`;

            await fetch(url)
                .then(async response => {

                    if (!response.ok || (response.url && response.url.indexOf("Identity/Account") > -1))
                        await this.errorHandler.ReturnProblemDetails(response, callBackErrorFn);
                    else {

                        this.consoleLog.Log(url);
                        if (callBackSuccessFn)
                            callBackSuccessFn();

                    }

                })
                .catch(error => {
                    this.errorHandler.ReturnErrorMessage(error, callBackErrorFn);
                });

        } catch (error) {
            this.errorHandler.ReturnErrorMessage(error, callBackErrorFn);
        }

    }

    async Fetch(url, data, callBackSuccessFn, callBackErrorFn) {

        try {
            if (data)
                this.consoleLog.Log(`POST: ${url}`, data)
            else
                this.consoleLog.Log(`GET: ${url}`)

            await fetch(url, data)
                .then(async response => {
                    if (!response.ok || (response.url && response.url.indexOf("Identity/Account") > -1))
                        await this.errorHandler.ReturnProblemDetails(response, callBackErrorFn);
                    else {
                        let data = await response.json();
                        if (data) {
                            if (data.hasOwnProperty('errors')) {

                                this.consoleLog.LogError(data.errors);
                                if (callBackErrorFn)
                                    callBackErrorFn({ error: data.errors });

                            } else {

                                this.consoleLog.Log(url, data);
                                if (callBackSuccessFn)
                                    callBackSuccessFn(data);
                            }
                        } else {
                            this.errorHandler.ReturnErrorMessage("We couldn't find what you were looking for.", callBackErrorFn);
                        }
                    }
                })
                .catch(error => {
                    this.errorHandler.ReturnErrorMessage(error, callBackErrorFn);
                });
        } catch (error) {
            this.errorHandler.ReturnErrorMessage(error, callBackErrorFn);
        }
    }
}

export class ReportApi {

    constructor(endPoint) {
        this.endPoint = endPoint ?? "report";
        this.errorHandler = new ErrorHandler();
        this.consoleLog = new ConsoleLogging();
    }

    // ReportApi
    async BeaconStatus(callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/BeaconStatus`;
        await this.FetchBlob(url, null, callBackSuccessFn, callBackErrorFn);

    }

    // ReportApi
    async BeaconSource(callBackSuccessFn, callBackErrorFn) {

        let url = `${this.endPoint}/BeaconSource`;
        await this.FetchBlob(url, null, callBackSuccessFn, callBackErrorFn);

    }

    async FetchBlob(url, data, callBackSuccessFn, callBackErrorFn) {

        try {
            if (data) {
                this.consoleLog.Log(`POST: ${url}`, data);
            }
            else {
                this.consoleLog.Log(`GET: ${url}`)
                data = {
                    method: 'GET',
                };
            }

            await fetch(url, data)
                .then(async response => {
                    if (!response.ok || (response.url && response.url.indexOf("Identity/Account") > -1))
                        await this.errorHandler.ReturnProblemDetails(response, callBackErrorFn);
                    else {
                        let data = await response.blob();
                        if (data) {

                            this.consoleLog.Log(url, "File Downloaded");
                            if (callBackSuccessFn)
                                callBackSuccessFn(data);

                        } else {
                            this.errorHandler.ReturnErrorMessage("We couldn't find what you were looking for.", callBackErrorFn);
                        }
                    }
                })
                .catch(error => {
                    this.errorHandler.ReturnErrorMessage(error, callBackErrorFn);
                });
        } catch (error) {
            this.errorHandler.ReturnErrorMessage(error, callBackErrorFn);
        }
    }

    async Fetch(url, data, callBackSuccessFn, callBackErrorFn) {

        try {
            if (data)
                this.consoleLog.Log(`POST: ${url}`, data)
            else
                this.consoleLog.Log(`GET: ${url}`)

            await fetch(url, data)
                .then(async response => {
                    if (!response.ok || (response.url && response.url.indexOf("Identity/Account") > -1))
                        await this.errorHandler.ReturnProblemDetails(response, callBackErrorFn);
                    else {
                        let data = await response.json();
                        if (data) {
                            if (data.hasOwnProperty('errors')) {

                                this.consoleLog.LogError(data.errors);
                                if (callBackErrorFn)
                                    callBackErrorFn({ error: data.errors });

                            } else {

                                this.consoleLog.Log(url, data);
                                if (callBackSuccessFn)
                                    callBackSuccessFn(data);
                            }
                        } else {
                            this.errorHandler.ReturnErrorMessage("We couldn't find what you were looking for.", callBackErrorFn);
                        }
                    }
                })
                .catch(error => {
                    this.errorHandler.ReturnErrorMessage(error, callBackErrorFn);
                });
        } catch (error) {
            this.errorHandler.ReturnErrorMessage(error, callBackErrorFn);
        }
    }
}
