import React, { Component } from 'react';
import { ButtonContinueGame } from '../Buttons/ContinueGame';
import { GameProgress } from './GameProgress';
import { GameAwardsByScore } from './GameAwardsByScore';
import Table from 'react-bootstrap/Table';
import { Format } from '../GlobalFns'

export class BrainTrainingSummary extends Component {

    constructor(props) {
        super(props);

        this.state = {
        }

        this.format = new Format();
    }

    HasValidResults() {
        return this.props.resultsSummary && this.props.resultsSummary.passedCount > 0;
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps) {

    }

    render() {
        if (this.HasValidResults()) {

            return (
                <div>
                    <Table className="noEndBorder transparent-table m-0" variant={this.props.theme.GetPlayBackgroundVariant()}>
                        <tbody>
                            <tr>
                                <td><span className="jumboLabel">{this.props.resultsSummary.mostRecentComplexity}</span></td>
                                <td className="align-middle w-100"><h2>Your<br />Score</h2></td>
                            </tr>
                        </tbody>
                    </Table>

                    {!this.props.isPlacebo && (
                        <GameAwardsByScore
                            resultsSummary={this.props.resultsSummary}
                            user={this.props.user}
                            device={this.props.device}
                            theme={this.props.theme}
                        />
                    )}

                    {this.props.hasInlineContinueButton && (
                        <div className="d-flex justify-content-center p-3 pb-4">
                            <ButtonContinueGame text="Play Game" variant={this.props.theme.GetPlayBackgroundVariant()} onClick={() => { if (this.props.onStart) this.props.onStart() }} />
                        </div>
                    )}

                    <GameProgress
                        timePlayedToday={this.props.resultsSummary.timePlayedToday}
                        targetMinutesDay={this.props.cognitiveItem.targetMinutesDay}
                        timePlayedLast7Days={this.props.resultsSummary.timePlayedLast7Days}
                        targetMinutes7Days={this.props.cognitiveItem.targetMinutes7Days}
                        timePlayedLast30Days={this.props.resultsSummary.timePlayedLast30Days}
                        targetMaxMinutesDay={this.props.cognitiveItem.maxMinutesDay}
                        theme={this.props.theme}
                        maxComplexity={this.props.resultsSummary.maxComplexity}
                        user={this.props.user}
                        device={this.props.device}
                        showTargetProgress={this.props.showTargetProgress}
                        placebo={this.props.placebo}
                    />

                </div>
            );
        }
    }

}
