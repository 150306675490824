import React, { Component } from 'react';
import { Format } from './shared/GlobalFns';
import { PlayPackageForm } from './Battery/PlayPackageForm';
import { BatteryApi } from './shared/CTAGApi';

import './Games.css'

export class Games extends Component {
    static displayName = Games.name;

    constructor(props) {
        super(props);

        this.state = {
            study: null,
            schedule: null,
            isBusy: true,
            selectedActivity: null,
            showModal: {
                playQuestionnaire: false,
                playBattery: false,
            },
            scrollPosition: 0,
            package: {},
            activity: null,
        }

        this.batteryApi = new BatteryApi();

        this.Format = new Format();
    }

    async GetData() {
        await this.batteryApi.GetByCode("BEACON_PACKAGE", 1, (data) => {

            let activity = null;
            if (this.state.schedule && this.state.schedule.stages) {

                this.state.schedule.stages.every(stage => {
                    let activities = stage.activities.filter(activity => activity.type === "PACKAGE" && (activity.status === "OPEN" || activity.status === "ACTIVE"));

                    if (activities.length > 0)
                        activity = activities[activities.length - 1];
                        
                    return activities.length <= 0;
                });

            }
            //console.log("OPEN", activity);
            this.setState({ package: data, activity: activity })

        }, (errors) => {

        });
    }

    LoadPage() {

        this.setState({ schedule: this.props.schedule, isBusy: false }, () => {
            this.GetData();
        });
    }

    componentDidMount() {
        this.LoadPage();
    }

    componentDidUpdate(prevProps) {
        if (this.props.schedule !== prevProps.schedule)
            this.LoadPage();
    }

    render() {
        if (this.state.isBusy) {

            return (
                <div></div>
            )

        } else if (this.state.schedule) {

            if (this.state.activity) {
                return (
                    <div className="flex-fill">

                        <PlayPackageForm
                            mode={this.state.activity.mode}
                            inputType={this.props.device.IsTouchDevice() ? "TOUCH" : "KEYBOARD"}

                            cognitiveItemType="BRAINTRAINING"

                            nextEnabled={false}
                            index="0"
                            item={this.state.package}
                            onSavedBatteryResults={(cognitiveItemResults) => this.SavedBatteryResults(cognitiveItemResults)}
                            onUpdateHeader={(header) => this.UpdateHeader(header)}
                            onUpdateOptions={(options) => this.setState({ options: options })}
                            user={this.props.user}
                            device={this.props.device}
                            theme={this.props.theme}
                        />

                    </div>
                );
            } else {
                return (
                    <div className="p-5">
                        <h1>Nearly Ready</h1>
                        <p>You can start the brain training games when you've completed all the baseline activities on your pathway.</p>
                    </div>
                );
            }
        }
    }

}
